// библиотеки
import { defineStore } from 'pinia';

export const useHeaderStore = defineStore('HeaderStore', () => {
  const theme = reactive({
    isDark: false,
  });

  return { theme };
});
